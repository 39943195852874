@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600&display=swap');

$scrollbar-thumb-color: #d29835;
$scrollbar-track-color: #d4d4d466;

body {
    margin: 0;
    min-width: 100vw;
    color: whitesmoke;
    overflow-y: auto;
    overflow-x: hidden;
}

#root {
    min-width: 100vw;
    min-height: 100vh;
}

// MVX
.Login-Button {
    margin: 0 !important;
    padding: 13px 15px !important;
    border: 0 !important;
    outline: none !important;
    background-color: #3d456e !important;

    &:hover {
        background-color: #4f5b91 !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.15rem #4c568a60 !important;
    }
}

// Dapp Sign Tx Modal
.Sign-Tx-Modal {
    .dapp-modal-dialog-content-body {
        background-color: #222222 !important;
    }

    .dapp-modal-dialog-content {
        border: none !important;
    }
}

.Tx-Toast {
    font-family: 'IBM Plex Mono', monospace !important;
    padding: 0.75rem 0.5rem !important;
    background-color: #222222 !important;
    overflow: hidden;

    .dapp-core-component__transactionToast-styles__heading {
        margin-bottom: 0.5rem;
    }

    .dapp-core-component__transactionDetails-styles__status {
        font-size: 14px;
        font-weight: 600;
        margin: 0.5rem 0 0.5rem;
    }

    .dapp-core-component__transactionDetails-styles__container {
        margin-bottom: 0;
    }

    h5 {
        font-size: 17px;
        margin-bottom: 0;
        font-weight: 600;
    }

    .dapp-core-component__progressStyles__progress .dapp-core-component__progressStyles__bar {
        top: -0.75rem;
        bottom: -0.75rem;
    }

    .dapp-core-component__transactionToast-styles__close {
        color: #fff !important;
    }
}

.dapp-core-component__transactionToast-styles__content
    .dapp-core-component__transactionToast-styles__left
    .dapp-core-component__transactionToast-styles__icon
    .Custom-Error-Toast-Icon {
    background: #d9534f;
}

.dapp-failed-transaction-status-toast {
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 15px;
    font-weight: 600;

    .dapp-core-component__customToast-styles__close {
        color: #fff !important;
    }

    .dapp-core-component__transactionsToastList-styles__toastWrapper {
        background-color: #222222 !important;
        // margin-bottom: 0 !important;
    }
}

.dapp-custom-toast {
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 0.5rem !important;
    background-color: #222222 !important;
    // margin-bottom: 0 !important;

    h5 {
        font-size: 17px;
        margin-bottom: 0;
        font-weight: 700;
    }

    .dapp-core-component__transactionToast-styles__content
        .dapp-core-component__transactionToast-styles__left
        .dapp-core-component__transactionToast-styles__icon {
        background: pink;
    }

    .dapp-core-component__customToast-styles__close {
        color: #fff !important;
    }
}

// MVX Modals
.dapp-core-component__dappModalStyles__dappModalContent {
    background-color: #222222 !important;
}

.dapp-core-component__main__btn-light {
    color: #868686 !important;
    background-color: #222222 !important;
}

.dapp-core-component__dappModalStyles__dappModal {
    z-index: 9999 !important;
}

// Scrollbar
.Scrollbar-Gutter {
    scrollbar-gutter: stable;
}

/* Firefox */
* {
    scrollbar-width: none;
    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 26px;
}

*::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
    border-left: 22px solid transparent;
    border-right: 2px solid transparent;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: 0px;
    border-left: 20px solid transparent;
    background-clip: padding-box;
}
